import React, { ReactElement } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { TSearchPage } from '@mindfulchefuk/components/pages/SearchPage/SearchPage'
import { recipeSearchPageFilterDefaults } from '@mindfulchefuk/config/recipeSearchPageFilterDefaults'

const SearchPage = dynamic<TSearchPage>(() =>
  import(
    /* webpackChunkName: "SearchPage" */ '@mindfulchefuk/components/pages/SearchPage/SearchPage'
  ).then((lib) => lib.SearchPage)
)

const BasePage = (): ReactElement => {
  const router = useRouter()

  const { basePage } = router.query as { basePage: string }

  const recipeLibraryPage = recipeSearchPageFilterDefaults.baseUrl.find(
    ({ slug }) => slug === basePage
  )

  if (recipeLibraryPage) {
    return (
      <SearchPage
        slug={recipeLibraryPage.slug}
        filter={recipeLibraryPage.filter}
      />
    )
  }
}

export default BasePage
